
import { WORKSHEET_EXECUTION_FEEDBACK, SCHOOL_GRADE } from '@instruia/utils'
import axios from '../../../../modules/axios'
import { IWorksheetExecution } from '../types'
import { IAggregatedWorksheet } from '../../types'
import { IWorksheetExecutionChatMessageExecution } from '../chat-message/execution/types'
import { IWorksheetExecutionChatMessage } from '../chat-message/types'

export async function setWorksheetExecutionFeedback (
  worksheet: string,
  worksheetExecutionId: string,
  feedback: WORKSHEET_EXECUTION_FEEDBACK
): Promise<IWorksheetExecution> {
  const response = await axios({
    method: `patch`,
    url: `/worksheets/${worksheet}/executions/${worksheetExecutionId}/feedback`,
    data: {
      feedback
    }
  })

  return response.data
}

export async function regenerateWorksheetExecution (
  worksheetPlanId: string,
  worksheetExecutionId: string,
  theme: string | null,
  grade: SCHOOL_GRADE | null,
  exerciseCount: number
): Promise<IAggregatedWorksheet> {
  const response = await axios({
    method: `post`,
    url: `/worksheets/${worksheetPlanId}/executions/${worksheetExecutionId}/regenerate`,
    data: {
      theme,
      grade,
      exerciseCount
    }
  })

  return response.data
}

export async function sendWorksheetExecutionMessage (
  worksheetId: string,
  worksheetExecutionId: string,
  content: string
): Promise<IAggregatedWorksheet> {
  const response = await axios({
    method: `post`,
    url: `/worksheets/${worksheetId}/executions/${worksheetExecutionId}/chat-messages`,
    data: {
      content
    }
  })

  return response.data
}

export async function getWorksheetCurrentExecutionLastChatMessageExecution (
  worksheetId: string,
  worksheetExecutionId: string
): Promise<IWorksheetExecutionChatMessageExecution> {
  const response = await axios({
    method: `get`,
    url: `/worksheets/${worksheetId}/executions/${worksheetExecutionId}/chat-messages/executions/last`
  })

  return response.data
}

export async function getWorksheetCurrentExecutionChatMessages (
  worksheetId: string,
  worksheetExecutionId: string
): Promise<IWorksheetExecutionChatMessage[]> {
  const response = await axios({
    method: `get`,
    url: `/worksheets/${worksheetId}/executions/${worksheetExecutionId}/chat-messages`
  })

  return response.data
}
