import React, { ReactElement } from 'react'

import { Box } from '@mui/material'
import SideBar from './side-bar'
import { useIsMobile } from 'src/modules/utils'

interface IProps {
  editor: ReactElement
  executionView: ReactElement
}
export default function FeatureView (props: IProps) {
  const isMobile = useIsMobile()
  const drawerWidth = isMobile ? 0 : 100

  return (
    <>
      {!isMobile && (
        <SideBar drawerWidth={drawerWidth} />
      )}
      <Box
        sx={{
          minHeight: `calc(100vh - 40px)`,
          backgroundColor: `#EAF0F3`,
          marginLeft: `${drawerWidth}px`,
          padding: `20px`,
          display: `flex`,
          flexDirection: `column`,
          alignItems: `center`,
          justifyContent: `start`,
          gap: `20px`
        }}>
        <Box sx={{ flexGrow: 1, width: `100%`, display: `flex`, flexDirection: isMobile ? `column` : `row` }} >
          {/* IZQ */}
          <Box sx={{ flexBasis: isMobile ? `100%` : `50%`, display: `flex`, flexDirection: `row`, justifyContent: `center` }} >
            {props.editor}
          </Box>

          {/* DER */}
            <Box sx={{ flexBasis: isMobile ? `100%` : `50%`, display: `flex`, flexDirection: `column`, backgroundColor: `#FCFCFC` }} >
              {props.executionView}
            </Box>
        </Box>
      </Box>
    </>
  )
}
