
import { LESSON_PLAN_STEP, SCHOOL_GRADE, SCHOOL_SUBJECT } from '@instruia/utils'
import axios from '../../../modules/axios'
import { ILessonPlan, IAggregatedLessonPlan } from '../types'
import { ILessonPlanExecution } from '../execution/types'

const PATH = `lesson-plans`

export async function createLessonPlan (
  subject: SCHOOL_SUBJECT | null,
  theme: string | null,
  grade: SCHOOL_GRADE | null,
  additionalDetails: string | null,
  step: LESSON_PLAN_STEP
): Promise<ILessonPlan> {
  const response = await axios({
    method: `post`,
    url: `/${PATH}`,
    data: {
      subject,
      theme,
      grade,
      additionalDetails,
      step
    }
  })

  return response.data
}

export async function getLessonPlan (lessonPlanId: string): Promise<IAggregatedLessonPlan> {
  const response = await axios({
    method: `get`,
    url: `/${PATH}/${lessonPlanId}`
  })

  return response.data
}

export async function updateLessonPlan (
  lessonPlanId: string,
  subject: SCHOOL_SUBJECT,
  theme: string | null,
  grade: SCHOOL_GRADE | null,
  additionalDetails: string | null,
  // if null then do not update step
  step: LESSON_PLAN_STEP | null,
  shouldExecute: boolean
): Promise<IAggregatedLessonPlan> {
  const response = await axios({
    method: `put`,
    url: `/${PATH}/${lessonPlanId}`,
    data: {
      subject,
      theme,
      grade,
      additionalDetails,
      step,
      shouldExecute
    }
  })

  return response.data
}

export async function getLessonPlanCurrentExecution (lessonPlanId: string): Promise<ILessonPlanExecution> {
  const response = await axios({
    method: `get`,
    url: `/${PATH}/${lessonPlanId}/executions/current`
  })

  return response.data
}
