
import { STUDENT_REPORT_EXECUTION_FEEDBACK, SCHOOL_GRADE } from '@instruia/utils'
import axios from '../../../../modules/axios'
import { IStudentReportExecution } from '../types'
import { IAggregatedStudentReport } from '../../types'
import { IStudentReportExecutionChatMessageExecution } from '../chat-message/execution/types'
import { IStudentReportExecutionChatMessage } from '../chat-message/types'

export async function setStudentReportExecutionFeedback (
  studentReportId: string,
  studentReportExecutionId: string,
  feedback: STUDENT_REPORT_EXECUTION_FEEDBACK
): Promise<IStudentReportExecution> {
  const response = await axios({
    method: `patch`,
    url: `/student-reports/${studentReportId}/executions/${studentReportExecutionId}/feedback`,
    data: {
      feedback
    }
  })

  return response.data
}

export async function regenerateStudentReportExecution (
  studentReportId: string,
  studentReportExecutionId: string,
  grade: SCHOOL_GRADE | null,
  pronouns: string | null,
  strengths: string | null,
  opportunities: string | null
): Promise<IAggregatedStudentReport> {
  const response = await axios({
    method: `post`,
    url: `/student-reports/${studentReportId}/executions/${studentReportExecutionId}/regenerate`,
    data: {
      grade,
      pronouns,
      strengths,
      opportunities
    }
  })

  return response.data
}

export async function sendStudentReportExecutionMessage (
  studentReportId: string,
  studentReportExecutionId: string,
  content: string
): Promise<IAggregatedStudentReport> {
  const response = await axios({
    method: `post`,
    url: `/student-reports/${studentReportId}/executions/${studentReportExecutionId}/chat-messages`,
    data: {
      content
    }
  })

  return response.data
}

export async function getStudentReportCurrentExecutionLastChatMessageExecution (
  studentReportId: string,
  studentReportExecutionId: string
): Promise<IStudentReportExecutionChatMessageExecution> {
  const response = await axios({
    method: `get`,
    url: `/student-reports/${studentReportId}/executions/${studentReportExecutionId}/chat-messages/executions/last`
  })

  return response.data
}

export async function getStudentReportCurrentExecutionChatMessages (
  studentReportId: string,
  studentReportExecutionId: string
): Promise<IStudentReportExecutionChatMessage[]> {
  const response = await axios({
    method: `get`,
    url: `/student-reports/${studentReportId}/executions/${studentReportExecutionId}/chat-messages`
  })

  return response.data
}
